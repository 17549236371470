import React, { useState }  from "react"
import axios from "axios";


const ContactForm = () => {

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/c907d098-d4e1-4b5e-85fc-9774c2ca663f",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Kiitos, otan pikaisesti yhteyttä!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    return (
        <div className="content" style={{ marginTop: '1.7rem', marginLeft: '-15px' }}>
            <div className="col-md-8 mt-5">
                <h3>Yhteydenottopyyntö</h3>
                <form onSubmit={handleOnSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Nimi</label>
                        <input type="text" aria-label={'name'} name="name" className="form-control" id="name" placeholder="Anna nimesi" required="required"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Puhelinnumero</label>
                        <input type="text" name="phone" aria-label={'phone'}  className="form-control" id="phone" placeholder="Anna puhelinnumero"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" required="required">Sähköpostiosoite</label>
                        <input type="email" name="email" aria-label={'email'} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Syötä sähköpostiosoite" required="required"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Viesti</label>
                        <textarea type="text" aria-label={'message'} name="message" className="form-control" id="message" placeholder="Viesti" rows={3} required="required"/>
                    </div>
                    <button type="submit" className="btn btn-primary"  disabled={serverState.submitting}>
                        Lähetä
                    </button>
                    {serverState.status && (
                        <h5 style={{ color: '#317ac9', marginTop: '1.7rem' }} className={!serverState.status.ok ? "errorMsg" : ""}>
                            {serverState.status.msg}
                        </h5>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ContactForm;